
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

export default defineComponent({
  name: "underwriter-settlement-offers",
  components: {},
  props: {
    redirect: String,
    policySummary: Object,
  },
  data() {
    return {
      offers: [],
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Settlement Offers", ["Clients", "Claims"]);
    });
  },
  created() {
    this.getSettlmentOffers();
    this.displayDatatable();

    //Set page title
    document.title =
      "Underwriter: Settlement Offers | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    // Get list of settlement offfers from all underwriters
    getSettlmentOffers() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get("/underwriter/settlement-offers")
          .then(({ data }) => {
            // Assign data to variables
            this.offers = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    displayDatatable() {
      setTimeout(() => {
        //Add jQuery datatable
        $("#basicExample").DataTable({
          iDisplayLength: 10,
          language: {
            searchPlaceholder: "Search...",
            sSearch: "",
            lengthMenu: "_MENU_ items/page",
            // "lengthMenu": "Display _MENU_ records per page",
            zeroRecords: "No matching records found",
            // "info": "Showing page _PAGE_ of _PAGES_",
            infoEmpty: "No records available",
            infoFiltered: "(filtered from _MAX_ total records)",
          },
          processing: true,
          destroy: true,
        });
      }, 3000);
    },
  },
});
